<template>
    <div class="position-relative chat-statistics bg-light"
         v-observe-visibility="visibilityChanged">
        <b-overlay v-bind:show="isLoading">
            <div class="select-mode d-flex flex-nowrap align-items-center pl-2 pr-1 py-1">
                <div class="flex-grow-1">
                    <h6 class="text-uppercase mb-0">
                        <strong>Messages</strong>
                    </h6>
                    <p class="mb-0">
                        <small>Sum: {{ dataTotal }} | Avg: {{ dataAverage }} | Max: {{ dataMax }} | Min: {{ dataMin }}</small>
                    </p>
                </div>
                <div>
                    <b-form-select class=""
                                   size="sm"
                                   v-model="chartMode">
                        <option value="1">
                            Today
                        </option>
                        <option value="7">
                            7 Days
                        </option>
                        <option value="14">
                            14 Days
                        </option>
                        <option value="30">
                            30 Days
                        </option>
                    </b-form-select>
                </div>
            </div>
            <div class="chart-wrapper">
                <chart v-if="data.length > 0 && isVisible"
                       v-bind:labels="labels"
                       v-bind:chart-data="data">
                </chart>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import { getChatStatistics, } from "@/workers/whatsapp.worker";

import lightFormat from "date-fns/lightFormat";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import eachDayOfInterval from "date-fns/eachDayOfInterval";

import { ObserveVisibility, } from "vue-observe-visibility";

import { BOverlay, BFormSelect, } from "bootstrap-vue";

import groupBy from "lodash/groupBy";
import sum from "lodash/sum";

export default {
    name: "ChatStatistics",
    components: {
        BOverlay, BFormSelect,
        Chart: () => import("./Chart"),
    },
    directives: {
        "observe-visibility": ObserveVisibility,
    },
    props: {
        "chat": {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            isVisible: false,
            isLoading: false,

            chartMode: "14",
            data: [],
        };
    },
    computed: {
        dataAggregated () {
            let data = groupBy(
                (this.data ?? []).filter(
                    ({ result }) => result !== "moving-average"
                ),
                "_time"
            );
            data = Object
                .keys(data)
                .map(
                    key => data[key].reduce(
                        (acc, { _value }) => {
                            acc += _value;
                            return acc;
                        },
                        0
                    )
                );
            data[data.length - 2] += data?.[data.length - 1] ?? 0
            return data;
        },
        dataTotal () {
            return this.data.length > 0 ?
                sum(this.dataAggregated)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") :
                "TBA";
        },
        dataAverage () {
            return this.data.length > 0 ?
                Math.round(sum(this.dataAggregated) / parseInt(this.chartMode) - 1)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") :
                "TBA";
        },
        dataMax () {
            return this.data.length > 0 ?
                Math.max(...this.dataAggregated)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") :
                "TBA";
        },
        dataMin () {
            return this.data.length > 0 ?
                Math.min(...this.dataAggregated)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") :
                "TBA";
        },
        labels () {
            const now = new Date();
            return eachDayOfInterval({
                "start": startOfDay(subDays(now, parseInt(this.chartMode) - 1)),
                "end": endOfDay(now),
            })
                .map(day => lightFormat(new Date(day), "yyyy-MM-dd"));
        },
    },
    methods: {
        visibilityChanged (isVisible) {
            this.isVisible = isVisible;
            if (isVisible) {
                this.loadStatistics();
            }
        },
        async loadStatistics () {
            try {
                const now = new Date();
                this.isLoading = true;
                this.data = await getChatStatistics(
                    this.chat._id,
                    startOfDay(subDays(now, parseInt(this.chartMode) - 1)).getTime(),
                    endOfDay(now).getTime()
                );
            } catch (e) {
                console.error(e);
                throw e;
            } finally {
                this.isLoading = false;
            }
        },
    },
    watch: {
        "chartMode" () {
            this.loadStatistics();
        },
    },
}
</script>

<style lang="scss" scoped>
.chat-statistics {

}
</style>

<style lang="scss">
.chart-wrapper,
.chart {
    height: 300px;
}

.chart {
    position: relative;
}
</style>
